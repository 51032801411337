import React from "react";
import {Route, Switch} from "react-router-dom";
import InfoView from "../components/InfoView";
import {useDispatch, useSelector} from "react-redux";
import {MainRequestComponent} from "rk-react-js";

const App = ({match}) => {

  const dispatch = useDispatch()
  const rkMenu = useSelector(state => state?.RkMenu)
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        {rkMenu?.routes?.map(route => (
          <Route
            exact
            key={route?.id}
            path={`${match.url}${route?.path}`}
            component={(props) => (
              <>
                <MainRequestComponent
                  {...props}
                  dispatch={dispatch}
                  useSelector={useSelector}
                  request={route?.request}
                />
              </>
            )}
          />
        ))}
        {/*  next_route_rk_generator */}

      </Switch>
      <InfoView />
    </div>
  );
}

export default App;
