import {
  CUSTOMER_SET_CUSTOMERS,
  CUSTOMER_SET_CUSTOMER,
  CUSTOMER_DESTROY_CUSTOMER,
  CUSTOMER_CLEAR
} from '../../constants/ActionTypes'
import _ from 'lodash'

const INIT_STATE = {}

export const CustomerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CUSTOMER_SET_CUSTOMERS: {
      return _.mapKeys(action.payload, 'id')
    }
    case CUSTOMER_SET_CUSTOMER: {
      return { ...state, [action.payload.id]: action.payload }
    }
    case CUSTOMER_DESTROY_CUSTOMER: {
      return _.filter(state, (item) => item.id !== action.payload)
    }
    case CUSTOMER_CLEAR: {
      return {}
    }
    default:
      return state
  }
}
