import {MENU_SET_MENUS} from "../../constants/ActionTypes";

const INIT_STATE = {};

export const RkMenuReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MENU_SET_MENUS: {
      return action?.payload
    }
    default:
      return state;
  }
}
