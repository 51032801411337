import {DEFAULT_SET_DEFAULTS, DEFAULT_SET_DEFAULT} from "../../constants/ActionTypes";
import _ from "lodash";
import moment from "moment";

const INIT_STATE = {};

export const RkReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DEFAULT_SET_DEFAULTS: {
      return {
        ...state,
        [action?.payload?.model]: {
          data: _.mapKeys(action?.payload?.data, action?.payload?.key || 'id'),
          last_update: moment().format('YYYY-MM-DD HH:mm:ss')
        }
      }
    }
    case DEFAULT_SET_DEFAULT: {
      return {
        ...state,
        [action?.payload?.model]: {
          ...state?.[action?.payload?.model],
          data: {
            ...state[action?.payload?.model]?.data,
            [action?.payload?.modelId]: action?.payload?.data
          },
        }
      }
    }
    default:
      return state;
  }
}
