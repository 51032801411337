import React, {useEffect, useState} from "react";
import {Col, Row} from "antd";
import {useDispatch, useSelector} from "react-redux";

import {userSignIn} from "../appRedux/actions";
import forgot_password_background from "../assets/images/background/forgot_password_background.png"
import {WrapperScreenAuthentication} from "rk-react-js";
import FormForgotPassword from "../forms/FormForgotPassword";

const ForgotPassword = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(({auth}) => auth.token);
  const [isLoading, setLoading] = useState(false)

  const onFinish = async values => {
    setLoading(true)
    dispatch(userSignIn(values, {
      callback: () => {
        setLoading(false)
        props.history.push('/user')
      },
      fallback: () => setLoading(false)
    }));
  };

  useEffect(() => {
    if (token !== null) {
      // props.history.push('/');
    }
  }, [token, props.history]);

  return (
    <WrapperScreenAuthentication
      rightImageBackground={forgot_password_background}
      rightComponent={() => (
        <div style={{minHeight: '50vh'}} />
      )}
      leftComponent={() => (
        <Row style={{height: '100%', position: 'relative'}}>
          <Col
            span={18}
            offset={2}
            style={{margin: 'auto'}}
          >
            <FormForgotPassword onFinish={onFinish} isLoading={isLoading}/>
          </Col>
        </Row>
      )}
    />
  )
}

export default ForgotPassword;
