import {
  FETCH_ERROR, FETCH_ME,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET
} from "../../constants/ActionTypes";
import axios from '../../util/Api'
import {
  // disconnect,
  startConfiguration,
  // subscribe
} from 'pusher-redux';
import * as Sentry from '@sentry/browser';


export const startBroadcast = (token) => {
  if (!token) return null;
  console.log('Starting broadcast');
  startConfiguration({
    auth: {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json"
      }
    }
  });
  // subscribe('private-App.Admin', BROADCAST_COMMAND_RUN, COMMAND_RUN);
};

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({role, ...rest}, config) => (dispatch) => {
  dispatch({type: FETCH_START});
  axios.post('/auth/signup/'+ role, rest
  ).then(({data}) => {
    config.callback && config.callback(data)
    if (data) {
      localStorage.setItem("token", JSON.stringify(data.token));
      axios.defaults.headers.common['Authorization'] = "Bearer " + data.token;
      Sentry.configureScope(scope => {
        scope.setUser({
          id: data.user.data,
          name: data.user.name,
          email: data.user.email
        });
      });

      dispatch({type: FETCH_SUCCESS});
      dispatch({type: USER_TOKEN_SET, payload: data.token});
      config.callback && config.callback(data)
    }
  }).catch(function (error) {
    dispatch({type: FETCH_ERROR, payload: error.message});
    console.log("Error****:", error.message);
  });
};

export const userSignIn = ({email, password}, config) => (dispatch) => {
  dispatch({type: FETCH_START});
  axios.post('/auth/login', {
      email: email,
      password: password,
    }
  ).then(async ({data}) => {
    if (data) {
      localStorage.setItem("token", JSON.stringify(data.token));
      axios.defaults.headers.common['Authorization'] = "Bearer " + data.token;
      Sentry.configureScope(scope => {
        scope.setUser({
          id: data.user.data,
          name: data.user.name,
          email: data.user.email
        });
        // scope.setExtra('battery', 0.7);
        // scope.setTag('user_mode', 'admin');
        // scope.clear();
      });

      startBroadcast(data.token);
      dispatch({type: FETCH_SUCCESS});
      await dispatch({type: USER_TOKEN_SET, payload: data.token});
      config.callback && config.callback(data)
    } else {
      dispatch({type: FETCH_ERROR, payload: data.error});
    }
  }).catch(error => {
    console.log('error')
    config.fallback && config.fallback()
    dispatch({type: FETCH_ERROR, payload: error.message});
    console.log("Error****:", error.message);
  });
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/me',
    ).then(({data}) => {
      console.log("userSignIn: ", data);
      if (data.result) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const fetchMe = () => (dispatch) => {
  dispatch({type: FETCH_START});
  axios.get('user/me',
  ).then(({data}) => {
    if (data) {
      dispatch({type: FETCH_SUCCESS});
      dispatch({type: FETCH_ME, payload: data.data});
    } else {
      dispatch({type: FETCH_ERROR, payload: data.error});
    }
  }).catch(function (error) {
    dispatch({type: FETCH_ERROR, payload: error.message});
    console.log("Error****:", error.message);
  });
};


export const userSignOut = () => (dispatch) => {
  dispatch({type: FETCH_START});

  // axios.post('auth/logout').then(({data}) => {
  //   console.log("log out", data)
  //   if (data.result) {
  dispatch({type: FETCH_ME, payload: null})
  localStorage.removeItem("token");
  dispatch({type: FETCH_SUCCESS});
  dispatch({type: SIGNOUT_USER_SUCCESS});
  // } else {
  //   dispatch({type: FETCH_ERROR, payload: data.error});
  // }
  // }).catch(function (error) {
  //   dispatch({type: FETCH_ERROR, payload: error.message});
  //   console.log("Error****:", error.message);
  // });
  // }
};
