import {combineReducers} from "redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import MeReducer from "./MeReducer";
import {SupplierPhysicReducer} from "./SupplierPhysicReducer";
import {CompanySupplierReducer} from "./CompanySupplierReducer";
import {CompanyCustomerReducer} from "./CompanyCustomerReducer";
import {EmployeeReducer} from "./EmployeeReducer";
import {CustomerReducer} from "./CustomerReducer";
import {ConfigOptionReducer} from "./ConfigOptionReducer";
import {PurchaseReducer} from "./PurchaseReducer";
import {RkReducer} from './RkReducer';
import {RkMenuReducer} from './RkMenu';
import {connectRouter} from 'connected-react-router';
import {
  AppReducer,
  CompanyReducer,
  FilterReducer,
  LogRequestReducer,
  PaginationReducer,
  ProductReducer,
  SelectBankReducer,
  SelectCompanyReducer,
  SelectUserReducer,
  UserReducer,
  ParameterReducer,
  SelectCategoryReducer,
  RkRequestUpdateReducer
} from "rk-react-js";

export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  common: Common,
  App: AppReducer,
  Company: CompanyReducer,
  ConfigOption: ConfigOptionReducer,
  CompanySupplier: CompanySupplierReducer,
  CompanyCustomer: CompanyCustomerReducer,
  Customer: CustomerReducer,
  Employee: EmployeeReducer,
  Filter: FilterReducer,
  LogRequest: LogRequestReducer,
  Me: MeReducer,
  Pagination: PaginationReducer,
  Purchase: PurchaseReducer,
  Product: ProductReducer,
  Parameter: ParameterReducer,
  Rk: RkReducer,
  RkRequestUpdate: RkRequestUpdateReducer,
  RkMenu: RkMenuReducer,
  SelectCategory: SelectCategoryReducer,
  SupplierPhysic: SupplierPhysicReducer,
  SelectBank: SelectBankReducer,
  SelectUser: SelectUserReducer,
  SelectCompany: SelectCompanyReducer,
  User: UserReducer,
});
