import React from 'react'
import {Button, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ButtonFill = ({isLoading, onClick, text = ''}) => {
    return process.env.REACT_APP_DEV ? (
      <Tooltip
        title="AutoPreencher"
      >
          <Button
            type="link"
            loading={isLoading}
            onClick={onClick}
          ><FontAwesomeIcon icon={'pencil'}/>{text ? <span style={{marginLeft: 8}}>{text}</span> : null}</Button>
      </Tooltip>
      ) : null
}

export default ButtonFill
