import React from 'react'
import {Typography} from "antd";

const SectionForm = ({children}) => {
  return (
    <>
      <Typography.Paragraph style={{marginTop: 32}}>{children}</Typography.Paragraph>
    </>
  )
}

export default SectionForm
