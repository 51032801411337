import {
  CONFIG_OPTION_SET_CONFIG_OPTIONS,
} from '../../constants/ActionTypes'
import _ from 'lodash'

const INIT_STATE = {}

export const ConfigOptionReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONFIG_OPTION_SET_CONFIG_OPTIONS: {
      return _.groupBy(action?.payload, 'type')
    }
    default:
      return state
  }
}
