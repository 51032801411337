import React, {useEffect} from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import {THEME_TYPE_LITE} from "../../constants/ThemeSetting";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {MENU_SET_MENUS} from "../../constants/ActionTypes";
import {fetchDefaults} from "rk-react-js";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const MenuItem = Menu.Item;

const SidebarContentAdmin = ({defaultOpenKeys, selectedKeys, getNavStyleSubMenuClass}) => {
  const dispatch = useDispatch()
  let {navStyle, themeType} = useSelector(({settings}) => settings);
  const rkMenu = useSelector(state => state?.RkMenu)

  useEffect(() => {
    dispatch(fetchDefaults('/system/menu', {}, {
      withoutRedux: true,
      callback: ({data}) => {
        dispatch({type: MENU_SET_MENUS, payload: data})
      }
    }))
    // eslint-disable-next-line
  }, [])


  const renderMenu = (item) => {
    if (item?.menuItems?.length) {
      return (
        <MenuItemGroup
          key={item?.id}
          className="gx-menu-group"
          title={item?.title}
        >
          {item?.menuItems?.map(menuItem => renderMenu(menuItem))}
        </MenuItemGroup>
      )
    }

    if (item?.subitems?.length) {
      return (
        <SubMenu
          key={item?.id}
          popupClassName={getNavStyleSubMenuClass(navStyle)}
          title={(
            <span style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <FontAwesomeIcon
                icon={item?.icon}
                className={'fa-fw gx-mr-3'}
                size={'lg'}
              />
              <span>{item?.label}</span>
            </span>
          )}
        >
          {item?.subitems?.map(sub => renderMenu(sub))}
        </SubMenu>
      )
    }

    return (
      <MenuItem key={item?.id}>
        {(item?.path.search('http://') >= 0 || item?.path.search('https://') >= 0) ? (
          <a href={`${item?.path}`} target="_blank" rel="noopener noreferrer">
            {item?.icon ? (
              <FontAwesomeIcon
                icon={item?.icon}
                className={'fa-fw gx-mr-3'}
                size={'lg'}
              />
            ) : null}
            <span>{item?.label}</span>
          </a>
        ) : (
          <Link to={`${item?.path}`}>
            {item?.icon ? (

              <FontAwesomeIcon
                icon={item?.icon}
                className={'fa-fw gx-mr-3'}
                size={'lg'}
              />
            ) : null}
            <span>{item?.label}</span>
          </Link>
        )}
      </MenuItem>
    )
  }


  return (<>
    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
      mode="inline"
    >
      {rkMenu?.items?.map(item => renderMenu(item))}
    </Menu>
  </>);
};

SidebarContentAdmin.propTypes = {};
export default SidebarContentAdmin;
