import React from "react";
import {Space, Spin} from "antd";
// import loader from "../../assets/images/loader.svg"

const CircularProgress = ({className}) => <div className={`loader ${className}`}>
    {/*<img src={loader} alt="loader"/>*/}
    <Space size="middle">
        <Spin size="large"  />
    </Space>
</div>;
export default CircularProgress;
