import React, {useState} from 'react'
import {Form, Radio, Row, Space, Typography} from "antd";
import SectionForm from "../../components/SectionForm";
import {FormFilterComponent} from "rk-react-js";
import ButtonFill from "../../components/ButtonFill";
import {Link} from "react-router-dom";
const faker = require('faker-br')
const FormSignUp = ({onFinish}) => {
  const form = Form.useForm()
  const [role, setRole] = useState('customer')
  const [typePerson, setTypePerson] = useState('PF')
  return (
    <>
      <Typography.Title level={1}>Cadastro</Typography.Title>
      <SectionForm>CATEGORIA</SectionForm>
      <Radio.Group
        onChange={({target}) => setRole(target?.value)}
        value={role}
      >
        <Space direction="horizontal">
          <Radio value={'customer'}>Cliente</Radio>
          <Radio value={'supplier'}>Fornecedor</Radio>
        </Space>
      </Radio.Group>
      <SectionForm>TIPO</SectionForm>
      <Radio.Group
        onChange={({target}) => setTypePerson(target?.value)}
        value={typePerson}
      >
        <Space direction="horizontal">
          <Radio value={'PF'}>Pessoa Física</Radio>
          <Radio value={'PJ'}>Pessoa Jurídica</Radio>
        </Space>
      </Radio.Group>
      <br /><br /><br /><br />
      <FormFilterComponent
        form={form[0]}
        items={[
          {type: 'string', field: 'name', label: 'Nome', span: {xs: 24, sm: 12, md: 12, lg: 12}},
          {type: 'cpf', field: 'document', label: 'CPF', span: {xs: 24, sm: 12, md: 12, lg: 12}},
          {type: 'string', field: 'email', label: 'Email', span: {xs: 24, sm: 12, md: 12, lg: 12}},
          {type: 'string', field: 'phone', label: 'Telefone', span: {xs: 24, sm: 12, md: 12, lg: 12}},
          {type: 'string', field: 'password', label: 'Senha', span: {xs: 24, sm: 12, md: 12, lg: 12}},
          {
            type: 'string',
            field: 'password_confirmation',
            label: 'Confirmar Senha',
            span: {xs: 24, sm: 12, md: 12, lg: 12}
          },
          {
            type: 'button', field: 'button', text: 'Cadastrar', span: {xs: 24, sm: 24, md: 24, lg: 24}, onClick: () =>
            {
              form[0].validateFields().then((data) => {
                onFinish({...data, role, type: typePerson})
              })
            }
          },
        ]}
      />
      <Row justify={'center'} align={'center'}>
        <Link to="/signin"> Realizar Login</Link>
      </Row>
      <ButtonFill
        onClick={() => {
          const initFill = {
            name: `${faker.name.firstName()} ${faker.name.lastName()}`,
            document: faker.br.cpf(),
            email: faker.internet.email(),
            phone: faker.phone.phoneNumber(),
            password: '102030',
          }
          form[0].setFieldsValue(initFill)
        }}
      />
    </>
  )
}

export default FormSignUp
