import {
  SUPPLIER_PHYSIC_SET_SUPPLIER_PHYSICS,
  SUPPLIER_PHYSIC_SET_SUPPLIER_PHYSIC,
  SUPPLIER_PHYSIC_DESTROY_SUPPLIER_PHYSIC,
  SUPPLIER_PHYSIC_CLEAR
} from '../../constants/ActionTypes'
import _ from 'lodash'

const INIT_STATE = {}

export const SupplierPhysicReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUPPLIER_PHYSIC_SET_SUPPLIER_PHYSICS: {
      return _.mapKeys(action.payload, 'id')
    }
    case SUPPLIER_PHYSIC_SET_SUPPLIER_PHYSIC: {
      return { ...state, [action.payload.id]: action.payload }
    }
    case SUPPLIER_PHYSIC_DESTROY_SUPPLIER_PHYSIC: {
      return _.filter(state, (item) => item.id !== action.payload)
    }
    case SUPPLIER_PHYSIC_CLEAR: {
      return {}
    }
    default:
      return state
  }
}
