import React, {useEffect} from "react";
import {Col, Row} from "antd";

import {useDispatch, useSelector} from "react-redux";
import {userSignUp} from "../appRedux/actions";
import {WrapperScreenAuthentication} from "rk-react-js";
import signup_background from "../assets/images/background/signup_background.png";
import FormSignUp from "../forms/FormSignUp";

const SignUp = (props) => {

  const dispatch = useDispatch();
  const token = useSelector(({auth}) => auth.token);

  const onFinish = values => {
    dispatch(userSignUp({...values}, {
      callback: () => {
        props.history.push('/');
      }
    }));
  };

  useEffect(() => {
    if (token !== null) {
      props.history.push('/');
    }
  });

  return (
    <WrapperScreenAuthentication
      leftImageBackground={signup_background}
      leftComponent={() => (
        <div style={{minHeight: '50vh'}} />
      )}
      rightComponent={() => (
        <Row style={{height: '100%', position: 'relative'}}>
          <Col
            span={22}
            style={{margin: 'auto'}}
          >
            <FormSignUp onFinish={onFinish} />
          </Col>
        </Row>
      )}
    />
  )
};

export default SignUp;
