// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';

export const FETCH_ME = "FETCH_ME";

export const FETCH_TRANSCRIPTIONS = "FETCH_TRANSCRIPTIONS";

export const FETCH_PAYMENTS = "FETCH_PAYMENTS";

export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCT = "FETCH_PRODUCT";
// new
export const SUPPLIER_PHYSIC_SET_SUPPLIER_PHYSICS = 'SUPPLIER_PHYSIC_SET_SUPPLIER_PHYSICS';
export const SUPPLIER_PHYSIC_SET_SUPPLIER_PHYSIC = 'SUPPLIER_PHYSIC_SET_SUPPLIER_PHYSIC';
export const SUPPLIER_PHYSIC_DESTROY_SUPPLIER_PHYSIC = 'SUPPLIER_PHYSIC_DESTROY_SUPPLIER_PHYSIC';
export const SUPPLIER_PHYSIC_CLEAR = 'SUPPLIER_PHYSIC_CLEAR';



export const COMPANY_SUPPLIER_SET_COMPANY_SUPPLIERS = "COMPANY_SUPPLIER_SET_COMPANY_SUPPLIERS";
export const COMPANY_SUPPLIER_SET_COMPANY_SUPPLIER = "COMPANY_SUPPLIER_SET_COMPANY_SUPPLIER";
export const COMPANY_SUPPLIER_DESTROY_COMPANY_SUPPLIER = "COMPANY_SUPPLIER_DESTROY_COMPANY_SUPPLIER";
export const COMPANY_SUPPLIER_CLEAR = "COMPANY_SUPPLIER_CLEAR";

export const COMPANY_CUSTOMER_SET_COMPANY_CUSTOMERS = "COMPANY_CUSTOMER_SET_COMPANY_CUSTOMERS";
export const COMPANY_CUSTOMER_SET_COMPANY_CUSTOMER = "COMPANY_CUSTOMER_SET_COMPANY_CUSTOMER";
export const COMPANY_CUSTOMER_DESTROY_COMPANY_CUSTOMER = "COMPANY_CUSTOMER_DESTROY_COMPANY_CUSTOMER";
export const COMPANY_CUSTOMER_CLEAR = "COMPANY_CUSTOMER_CLEAR";

export const CUSTOMER_SET_CUSTOMERS = "CUSTOMER_SET_CUSTOMERS";
export const CUSTOMER_SET_CUSTOMER = "CUSTOMER_SET_CUSTOMER";
export const CUSTOMER_DESTROY_CUSTOMER = "CUSTOMER_DESTROY_CUSTOMER";
export const CUSTOMER_CLEAR = "CUSTOMER_CLEAR";

export const EMPLOYEE_SET_EMPLOYEES = "EMPLOYEE_SET_EMPLOYEES";
export const EMPLOYEE_SET_EMPLOYEE = "EMPLOYEE_SET_EMPLOYEE";
export const EMPLOYEE_DESTROY_EMPLOYEE = "EMPLOYEE_DESTROY_EMPLOYEE";
export const EMPLOYEE_CLEAR = "EMPLOYEE_CLEAR";

export const CONFIG_OPTION_SET_CONFIG_OPTIONS = "CONFIG_OPTION_SET_CONFIG_OPTIONS";


export const PURCHASE_SET_PURCHASES = 'PURCHASE_SET_PURCHASES';
export const PURCHASE_SET_PURCHASE = 'PURCHASE_SET_PURCHASE';
export const PURCHASE_DESTROY_PURCHASE = 'PURCHASE_DESTROY_PURCHASE';
export const PURCHASE_CLEAR = 'PURCHASE_CLEAR';

export const DEFAULT_SET_DEFAULTS = 'DEFAULT_SET_DEFAULTS';
export const DEFAULT_SET_DEFAULT = 'DEFAULT_SET_DEFAULT';

export const FETCH_CALENDAR_EVENTS = "FETCH_CALENDAR_EVENTS"
export const FETCH_CALENDAR_EVENT = "FETCH_CALENDAR_EVENT"

export const MENU_SET_MENUS = "MENU_SET_MENUS"
