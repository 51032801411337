import {
  COMPANY_CUSTOMER_SET_COMPANY_CUSTOMERS,
  COMPANY_CUSTOMER_SET_COMPANY_CUSTOMER,
  COMPANY_CUSTOMER_DESTROY_COMPANY_CUSTOMER,
  COMPANY_CUSTOMER_CLEAR
} from '../../constants/ActionTypes'
import _ from 'lodash'

const INIT_STATE = {}

export const CompanyCustomerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case COMPANY_CUSTOMER_SET_COMPANY_CUSTOMERS: {
      return _.mapKeys(action.payload, 'id')
    }
    case COMPANY_CUSTOMER_SET_COMPANY_CUSTOMER: {
      return { ...state, [action.payload.id]: action.payload }
    }
    case COMPANY_CUSTOMER_DESTROY_COMPANY_CUSTOMER: {
      return _.filter(state, (item) => item.id !== action.payload)
    }
    case COMPANY_CUSTOMER_CLEAR: {
      return {}
    }
    default:
      return state
  }
}
