import React from "react";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import {NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR, NAV_STYLE_NO_HEADER_MINI_SIDEBAR} from "../../constants/ThemeSetting";
import {useSelector} from "react-redux";
import SidebarContentAdmin from "./SidebarContentAdmin";

const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed}) => {

  let {navStyle} = useSelector(({settings}) => settings);
  let {pathname} = useSelector(({common}) => common);
  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile />
          {/*<AppsNavigation/>*/}
        </div>
        <CustomScrollbars
          className="gx-layout-sider-scrollbar"
        >
          <SidebarContentAdmin
            defaultOpenKeys={defaultOpenKeys}
            selectedKeys={selectedKeys}
            getNavStyleSubMenuClass={getNavStyleSubMenuClass}
          />
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;

