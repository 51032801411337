import {
  PURCHASE_SET_PURCHASES,
  PURCHASE_SET_PURCHASE,
  PURCHASE_DESTROY_PURCHASE,
  PURCHASE_CLEAR
} from '../../constants/ActionTypes'
import _ from 'lodash'

const INIT_STATE = {}

export const PurchaseReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PURCHASE_SET_PURCHASES: {
      return _.mapKeys(action.payload, 'id')
    }
    case PURCHASE_SET_PURCHASE: {
      return { ...state, [action.payload.id]: action.payload }
    }
    case PURCHASE_DESTROY_PURCHASE: {
      return _.filter(state, (item) => item.id !== action.payload)
    }
    case PURCHASE_CLEAR: {
      return {}
    }
    default:
      return state
  }
}
