import antdBr from "antd/lib/locale-provider/pt_BR";
import enMessages from "../locales/pt_BR.json";

const BrLang = {
  messages: {
    ...enMessages
  },
  antd: antdBr,
  locale: 'pt-BR',
};
export default BrLang;
