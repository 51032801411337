import React from 'react'
import {Button, Form, Image, Input, Typography} from "antd";
import logo from '../../assets/images/logo-fill.png'

const FormForgotPassword = ({onFinish, onFinishFailed, isLoading}) => {
  return (
    <>
      <Image
        src={logo}
        style={{marginBottom: 80, marginTop: 40}}
      />
      <Typography.Title level={1}>Esqueceu a senha</Typography.Title>
      <Typography.Paragraph>
        Digite o endereço de e-mail que você usou quando se inscreveu e enviaremos instruções para redefinir sua senha.
      </Typography.Paragraph>
      <br />
      <Typography.Paragraph style={{marginBottom: 40}}>
        Por motivos de segurança, NÃO armazenamos sua senha. Portanto, tenha certeza de que nunca enviaremos sua senha
        por e-mail.
      </Typography.Paragraph>
      <Form
        initialValues={{remember: true}}
        name="basic"
        layout={"vertical"}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >


        <Form.Item
          initialValue=""
          label="Email"
          rules={[{required: true, message: 'Email inválido'}]}
          name="email"
        >
          <Input
            placeholder="Email"
            data-testid="username"
            id={"username"}
          />
        </Form.Item>
        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            data-testid="handleSubmit"
            id="login"
            loading={isLoading}
          >
            <span>Recuperar senha</span>
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default FormForgotPassword
