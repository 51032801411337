import {applyMiddleware, compose, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
import thunk from 'redux-thunk';
import createRootReducer from '../reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {delayConfiguration} from 'pusher-redux'

const persistConfig = {
  key: 'root',
  storage,
}

const createBrowserHistory = require('history').createBrowserHistory;
export const history = createBrowserHistory();
const persistedReducer = persistReducer(persistConfig, createRootReducer(history))



const routeMiddleware = routerMiddleware(history);

const middlewares = [thunk, routeMiddleware];


export default function configureStore(preloadedState) {
  const store = createStore(
    persistedReducer, // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middlewares
      ),
    ),
  );

  delayConfiguration(store, process.env.REACT_APP_PUSHER_KEY,
    {
      wsHost: process.env.REACT_APP_PUSHER_HOST,
      authEndpoint: process.env.REACT_APP_PUSHER_AUTHENDPOINT,
      wsPort: process.env.REACT_APP_PUSHER_HOST_PORT,
      wssPort: process.env.REACT_APP_PUSHER_HOST_PORT,
      httpHost: process.env.REACT_APP_PUSHER_HOST,
      forceTLS: process.env.REACT_APP_PUSHER_TLS,
      httpPath: 'app',
      disableStats: true,
    });

  const persistor = persistStore(store)

  return {store, persistor};
}
