import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Popover} from "antd";
import {fetchMe, userSignOut} from "../../appRedux/actions";

const UserProfile = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.Me)
  useEffect(() => {
    if (!user || !user.name) {
      dispatch(fetchMe({},{}))
    }
    // eslint-disable-next-line
  }, [])
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );

  return (

    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar src='https://via.placeholder.com/150x150'
                className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
        <span className="gx-avatar-name">{user ? user.name : 'Carregando...'}<i
          className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
      </Popover>
    </div>

  )
};

export default UserProfile;
