import React, {useEffect, useState} from "react";
import {Button, Form, Input} from "antd";
import {useDispatch, useSelector} from "react-redux";

import {userSignIn} from "../appRedux/actions";


const SignIn = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(({auth}) => auth.token);
  const [isLoading, setLoading] = useState(false)

  const onFinishFailed = errorInfo => {
  };

  const onFinish = async values => {
    setLoading(true)
    dispatch(userSignIn(values, {
      callback: () => {
        setLoading(false)
        props.history.push('/user')
      },
      fallback: () => setLoading(false)
    }));
  };

  useEffect(() => {
    if (token !== null) {
      // props.history.push('/');
    }
  }, [token, props.history]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              {/*<img src="https://via.placeholder.com/272x395" alt='Neature'/>*/}
            </div>
            <div className="gx-app-logo-wid">
              <h1>Sistema APCEF-PR</h1>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src={require("../assets/images/logo-white.png")}/>
            </div>
          </div>
          <div className="gx-app-login-content">
            {/*{isLoading ? <CircularProgress/> : null}*/}
            <Form
              initialValues={{remember: true}}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">


              <Form.Item
                initialValue=""
                rules={[{required: true, message: 'Email inválido'}]} name="email">
                <Input placeholder="Email" data-testid="username" id={"username"}/>
              </Form.Item>
              <Form.Item
                initialValue="Rkuser1"
                rules={[{required: true, message: 'Senha obrigatória'}]} name="password">
                <Input type="password" placeholder="Password" data-testid="password"/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit" data-testid="handleSubmit" id="login" loading={isLoading}>
                  <span>Acessar</span>
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
