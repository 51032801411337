import {FETCH_ME} from "../../constants/ActionTypes";

const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ME: {
      return action.payload
    }
    default:
      return state;
  }
}
